var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "minusplusnumber" }, [
    _c(
      "div",
      {
        staticClass: "mpbtn minus",
        on: {
          click: function($event) {
            return _vm.mpminus()
          }
        }
      },
      [_vm._v(" - ")]
    ),
    _c("div", { attrs: { id: "field_container" } }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.newValue,
            expression: "newValue"
          }
        ],
        attrs: { type: "number", disabled: "" },
        domProps: { value: _vm.newValue },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.newValue = $event.target.value
          }
        }
      })
    ]),
    _c(
      "div",
      {
        staticClass: "mpbtn plus",
        on: {
          click: function($event) {
            return _vm.mpplus()
          }
        }
      },
      [_vm._v(" + ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }