var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mv-2", attrs: { sm: "12" } },
    [
      _c(
        "v-col",
        { staticClass: "pa-0 orderTable", attrs: { sm: "12" } },
        [
          _c("v-card-title", { staticClass: "orderHead" }, [
            _vm._v(" MY ORDER ")
          ]),
          _c("v-col", { staticClass: "ma=0 pa=0 tableHead" }, [
            _c(
              "div",
              { staticClass: "order-header" },
              [
                _c(
                  "v-row",
                  { staticClass: "m-0", attrs: { variant: "dark" } },
                  [
                    _c("v-col", { attrs: { cols: "5" } }, [
                      _c("h5", [_vm._v("ITEMS")])
                    ]),
                    _c("v-col", { attrs: { cols: "3" } }, [
                      _c(
                        "h5",
                        { staticClass: "text-center qty-label pl-0 ml-0" },
                        [_vm._v(" QTY ")]
                      )
                    ]),
                    _c("v-col", { attrs: { cols: "3" } }, [
                      _c("h5", { staticClass: "text-right" }, [
                        _vm._v(" PRICE ")
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("v-col", { staticClass: " myOrderList" }, [
            _c(
              "div",
              { staticClass: "m-0" },
              [
                _vm._l(_vm.$store.state["cart"].cartItems, function(
                  item,
                  index
                ) {
                  return _c(
                    "v-row",
                    { key: item.uuid },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "itemSection pr-0 pb-0",
                          attrs: { cols: "5" }
                        },
                        [
                          _c("p", { staticClass: "pa-0 mb-0" }, [
                            _vm._v(" " + _vm._s(item.name) + " ")
                          ]),
                          item.menu_modifiers
                            ? _c(
                                "v-row",
                                {
                                  staticClass: "d-flex pl-0 pt-2 pa-0",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pr-0 mr-0",
                                      attrs: { lg: "3" }
                                    },
                                    [
                                      item.menu_modifiers &&
                                      item.menu_modifiers.length > 0
                                        ? _c("v-img", {
                                            staticClass: "editIcon",
                                            attrs: {
                                              src: require("@/assets/images/icons/edit1.svg"),
                                              alt: "edit",
                                              variant: "link",
                                              color: "primary"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.customizeOrderUpdate(
                                                  item,
                                                  index
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pl-0 ml-0",
                                      attrs: { lg: "9" }
                                    },
                                    _vm._l(item.menu_modifiers, function(
                                      modifierGroup
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: modifierGroup.id,
                                          staticClass: "modifier-group",
                                          class:
                                            modifierGroup.selected_modifiers
                                              .length <= 0
                                              ? "hide-modgroup"
                                              : ""
                                        },
                                        [
                                          modifierGroup.selected_modifiers
                                            .length > 0
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "mod-group-item"
                                                },
                                                [
                                                  _c("small", [
                                                    _vm._v(
                                                      _vm._s(
                                                        modifierGroup.name
                                                      ) + ":"
                                                    )
                                                  ])
                                                ]
                                              )
                                            : _vm._e(),
                                          modifierGroup.selected_modifiers
                                            .length > 1
                                            ? _c(
                                                "div",
                                                { staticClass: "modifierDiv" },
                                                _vm._l(
                                                  modifierGroup.selected_modifiers,
                                                  function(modifier) {
                                                    return _c(
                                                      "small",
                                                      {
                                                        key: modifier.id,
                                                        staticClass:
                                                          "modifierName"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              modifier.name
                                                            ) +
                                                            ","
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _c(
                                                "div",
                                                { staticClass: "modifierDiv" },
                                                _vm._l(
                                                  modifierGroup.selected_modifiers,
                                                  function(modifier) {
                                                    return _c(
                                                      "small",
                                                      {
                                                        key: modifier.id,
                                                        staticClass:
                                                          "modifierName"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              modifier.name
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "itemSection orderQty",
                          attrs: { cols: "3" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "order-detail" },
                            [
                              _c("plus-minus-field", {
                                attrs: { value: item.qty },
                                on: {
                                  change: function($event) {
                                    return _vm.spin(
                                      item,
                                      $event,
                                      index,
                                      "update"
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-right itemSection priceitem",
                          attrs: { cols: "3" }
                        },
                        [
                          _c("p", [
                            _vm._v(_vm._s((item.price * item.qty).toFixed(2)))
                          ])
                        ]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "itemSection dltsection" },
                        [
                          _c("feather-icon", {
                            staticClass: "deleteButton",
                            attrs: { icon: "Trash2Icon", size: "24" },
                            on: {
                              click: function() {
                                _vm.deleteButton(index)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                _c("div", { staticClass: "line_segment" }),
                _c(
                  "v-row",
                  { staticClass: "subtotal" },
                  [
                    _c(
                      "v-col",
                      { staticClass: "justify-center", attrs: { cols: "6" } },
                      [_c("p", [_vm._v("Subtotal")])]
                    ),
                    _c(
                      "v-col",
                      { staticClass: "subtotal-price", attrs: { cols: "5" } },
                      [
                        _c("p", { staticClass: "text-right" }, [
                          _vm._v(" " + _vm._s(_vm.subTotal.toFixed(2)) + " ")
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _vm.discountAmount > 0
                  ? _c(
                      "v-row",
                      { staticClass: "packing_charges" },
                      [
                        _c(
                          "v-col",
                          { staticClass: "float-right", attrs: { cols: "6" } },
                          [_c("p", [_vm._v("Discount")])]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "packing-price",
                            attrs: { cols: "5" }
                          },
                          [
                            _c("p", { staticClass: "text-right" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.discountAmount.toFixed(2)) +
                                  " "
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-row",
                  { staticClass: "order-price " },
                  [
                    _c(
                      "v-col",
                      { staticClass: "totallbl", attrs: { cols: "6" } },
                      [_c("p", [_vm._v("Grand Total")])]
                    ),
                    _c(
                      "v-col",
                      { staticClass: "totalamount-col", attrs: { cols: "5" } },
                      [
                        _c("p", { staticClass: "text-right" }, [
                          _vm._v(
                            " €" + _vm._s(_vm.totalAmount.toFixed(2)) + " "
                          )
                        ])
                      ]
                    )
                  ],
                  1
                )
              ],
              2
            )
          ]),
          _c(
            "div",
            { staticClass: "checkOut" },
            [
              _vm.checkoutButton
                ? _c(
                    "v-btn",
                    {
                      staticClass: "footer-btn2 theme-btn font-weight-bold",
                      on: { click: _vm.navigateToCart }
                    },
                    [_vm._v(" CHECKOUT ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "390" },
          model: {
            value: _vm.deleteDialog,
            callback: function($$v) {
              _vm.deleteDialog = $$v
            },
            expression: "deleteDialog"
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass:
                "overflow-hidden d-flex flex-column align-items-center py-4 position-relative"
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "close-btn",
                  on: {
                    click: function($event) {
                      _vm.deleteDialog = false
                    }
                  }
                },
                [_vm._v(" mdi-close-circle-outline ")]
              ),
              _c(
                "v-icon",
                {
                  staticClass:
                    "d-flex justify-content-center text-xl-h2 text-secondary"
                },
                [_vm._v(" mdi-delete-forever ")]
              ),
              _c("v-card-text", { staticClass: "text-h6 text-center" }, [
                _vm._v(
                  " Do you really want to remove this item from your cart ? "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "deleteDialogueButton bg-secondary text-capitalize px-3 font-weight-medium",
                      attrs: { color: "white  accent-4", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.deleteItem()
                        }
                      }
                    },
                    [_vm._v(" Delete ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          ref: "custom",
          attrs: { id: "customize", width: "500" },
          model: {
            value: _vm.customizeDialog,
            callback: function($$v) {
              _vm.customizeDialog = $$v
            },
            expression: "customizeDialog"
          }
        },
        [
          _vm.activeItem
            ? _c(
                "v-container",
                { staticClass: "grey lighten-5 pt-0 pb-0 customise-popup" },
                [
                  _c("h2", { staticClass: "text-center pop-hd" }, [
                    _vm._v(" CUSTOMIZE ")
                  ]),
                  _c("div", { staticClass: "customise-header sticky" }, [
                    _c("h4", [_vm._v(_vm._s(_vm.activeItem.name))]),
                    _c("h5", [_vm._v(_vm._s(_vm.menuItemPrice))])
                  ]),
                  _c(
                    "v-row",
                    { staticClass: "mt-5 customise-heading" },
                    _vm._l(_vm.activeItem.menu_modifiers, function(
                      option,
                      index
                    ) {
                      return _c(
                        "v-col",
                        {
                          key: option.id,
                          staticClass: "mt-2",
                          attrs: { cols: "12", md: "12" }
                        },
                        [
                          _c("h2", [
                            _vm._v(
                              " " +
                                _vm._s(option.name) +
                                " (select min " +
                                _vm._s(option.min) +
                                " and max " +
                                _vm._s(option.max) +
                                ") "
                            )
                          ]),
                          option.min != 0 &&
                          option.min - option.selected_modifiers.length != 0 &&
                          option.selected_modifiers.length !== option.max
                            ? _c("h4", { staticClass: "red--text pt-1" }, [
                                _vm._v(
                                  " Choose min " +
                                    _vm._s(
                                      option.min -
                                        option.selected_modifiers.length
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _vm._l(option.modifiers, function(modifier) {
                            return _c(
                              "div",
                              {
                                key: modifier.name,
                                staticClass: "checkbox-div"
                              },
                              [
                                _c("v-checkbox", {
                                  attrs: {
                                    value: modifier,
                                    disabled:
                                      option.selected_modifiers.length >=
                                        option.max &&
                                      _vm.getItemIndex(index, modifier.id) ==
                                        -1,
                                    label: modifier.price
                                      ? modifier.name +
                                        "(€ " +
                                        modifier.price +
                                        ")"
                                      : modifier.name
                                  },
                                  model: {
                                    value: option.selected_modifiers,
                                    callback: function($$v) {
                                      _vm.$set(
                                        option,
                                        "selected_modifiers",
                                        $$v
                                      )
                                    },
                                    expression: "option.selected_modifiers"
                                  }
                                })
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    }),
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "modal-footer footer-sticky" },
                    [
                      _c("plus-minus-field", {
                        attrs: { value: _vm.activeItem.qty, type: undefined },
                        on: {
                          change: function($event) {
                            _vm.activeItem.qty = $event
                          }
                        }
                      }),
                      !_vm.isUpdate
                        ? _c(
                            "v-btn",
                            {
                              attrs: { disabled: _vm.selectedModifiersQty },
                              on: {
                                click: function($event) {
                                  return _vm.addToCart(_vm.activeItem)
                                }
                              }
                            },
                            [
                              _vm._v(
                                " ADD TO ORDER - " +
                                  _vm._s(
                                    (
                                      _vm.activeItem.qty *
                                      (parseFloat(_vm.activeItem.price) +
                                        _vm.activeItemModifierPrice())
                                    ).toFixed(2)
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.isUpdate
                        ? _c(
                            "v-btn",
                            {
                              attrs: { disabled: _vm.selectedModifiersQty },
                              on: {
                                click: function($event) {
                                  return _vm.updateCart(
                                    _vm.activeItem,
                                    "customize"
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                " UPDATE ORDER - " +
                                  _vm._s(
                                    (
                                      _vm.activeItem.qty *
                                      (parseFloat(_vm.activeItem.price) +
                                        _vm.activeItemModifierPrice())
                                    ).toFixed(2)
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }