<template>
  <div>
    <!-- <v-row no-gutters> -->
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      class="w-100"
    >
      <!-- <v-col> -->
      <div
        row
        class="ml-auto mr-auto"
      >
        <v-col class="d-flex ordertype-line">
          <h4 class="typeHeader mt-4 typeHeader-typ2">
            Order Type
          </h4>
          <v-radio-group
            v-model="orderType"
            row
            mandatory
            class=" pt-0 orderType"
            hide-details="auto"
            @change="orderTypeChange(orderType)"
          >
            <v-radio
              v-if="
                storeSettings.store['dinein'].allow_dinein &&
                  !isClosed('dinein')
              "
              label="Dine In"
              name="orderTypeName"
              value="dinein"
            />
            <v-radio
              v-if="
                storeSettings.store['collection'].allow_collection &&
                  orderDetails.table_no == '' &&
                  !isClosed('collection')
              "
              label="Collection"
              name="orderTypeName"
              value="collection"
              class="orderMargin"
              :disabled="orderDetails.table_no != ''"
            />
          </v-radio-group>
        </v-col>
        <v-col
          v-if="orderType == 'collection'"
          class="d-flex pickTimeAndDate"
        >
          <h4 class="typeHeader typeHeader-typ2">
            Collection Time
          </h4>
          <div class="wraper-select">
            <div class="select-typ1">
              <v-select
                v-model="intervelTime"
                hide-details="auto"
                :items="days"
                label="Date"
                class="pickDay ml-3"
                :value="days"
                return-object
                single-line
                :rules="[rules.required]"
                border
                solid
              />
            </div>
            <div class="select-typ1 picktime-select">
              <v-select
                v-model="pickupTime"
                hide-details="auto"
                :items="timeIntervels"
                label="Time"
                class="pickTime"
                return-object
                single-line
                :rules="[rules.required]"
              />
            </div>
          </div>
        </v-col>
        <!-- <v-col
            v-if="!isAdrress"
            class="d-flex pb-0"
          >
            <h4 class="typeHeader typeHeader-typ2">
              Pay By
            </h4>
            <v-radio-group
              v-model="paymentMethod"
              class="ml-14 mt-0 mb-0 pt-0"
              row
            >
              <v-radio
                label="Cash"
                value="cash_pay"
              />
              <v-radio
                label="Online"
                value="onlinee_pay"
              />
            </v-radio-group>
          </v-col> -->
      </div>
      <div
        v-if="isAdrress"
        row
        class="ml-auto mr-auto mb-0"
      >
        <v-expansion-panels v-if="orderType == 'delivery'">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <template #default="{ open }">
                <v-row no-gutters>
                  <v-col
                    cols="4"
                    class="text-no-wrap"
                  >
                    Delivery Address
                  </v-col>
                  <v-col
                    cols="8"
                    class="text--secondary"
                  >
                    <v-fade-transition leave-absolute>
                      <span
                        v-if="open"
                        key="0"
                      >
                        Please Choose your Existing Addresses
                      </span>
                    </v-fade-transition>
                  </v-col>
                </v-row>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-radio-group
                v-for="(address1, index) in $store.state.user.activeUser
                  .address"
                :key="index"
                v-model="addressRadioGroup"
                name="addressRadioGroup"
                class="address-radio"
              >
                <v-radio
                  :label="
                    address1.name +
                      ', ' +
                      address1.address +
                      ', ' +
                      address1.city
                  "
                  :value="address1.addressId"
                  @click="selectAddressFetch(index)"
                />
                <v-btn
                  class="ma-2"
                  text
                  icon
                >
                  <div class="text-right">
                    <div>
                      <v-icon
                        class="mr-3"
                        @click="editAddress(index, address1.addressId)"
                      >
                        mdi-pencil
                      </v-icon>
                      <v-icon @click="deleteButton(index)">
                        mdi-delete
                      </v-icon>
                    </div>
                  </div>
                </v-btn>
              </v-radio-group>

              <v-row justify="center">
                <v-dialog
                  v-model="addressDialog"
                  persistent
                  width="1200px"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      class="mb-4 mt-2"
                      v-on="on"
                      @click="addAddress()"
                    >
                      Add New Address
                    </v-btn>
                  </template>
                  <v-card>
                    <Address
                      :address-props="addressProps"
                      @change="val => (addressDialog = val)"
                    />

                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="closeAddressDialog()"
                      >
                        Close
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel />
        </v-expansion-panels>
        <v-col class="d-flex flex-column pb-0">
          <h4 class="typeHeader typeHeader-typ2">
            Contact Details
          </h4>
          <v-col class="mb-0 pa-0 contactDetails txt-input">
            <v-text-field
              v-if="orderTypeSettings.require_customer_name"
              v-model="orderAddressInfo.customerName"
              :rules="[rules.required, rules.name]"
              placeholder="Name *"
              outlined
              :disabled="$store.state.user.isLoggedIn"
            />
            <v-text-field
              v-model="orderAddressInfo.ordersMobile"
              :rules="[rules.required, rules.mobile]"
              placeholder="Mobile *"
              outlined
              class="mr-3"
            />

            <div
              v-if="orderType === 'delivery'"
              class="d-flex"
            >
              <v-text-field
                v-model="orderAddressInfo.ordersEmail"
                :rules="[rules.required, rules.email]"
                placeholder="E-mail *"
                outlined
              />
              <v-text-field
                v-model="orderAddressInfo.ordersCity"
                placeholder="City *"
                :rules="[rules.required, rules.city]"
                outlined
                class="mx-3"
              />
              <v-text-field
                v-model="orderAddressInfo.ordersAddress"
                :rules="[rules.required, rules.address]"
                placeholder="Street & Number *"
                outlined
              />
            </div>
            <h4 class="typeHeader typeHeader-typ2">
              Order Details
            </h4>
            <v-text-field
              v-if="orderTypeSettings.require_order_instructions"
              v-model="orderDetails.instructions"
              placeholder="Instuctions"
              outlined
            />
            <v-text-field
              v-if="orderType == 'dinein'"
              v-model="orderDetails.table_no"
              :rules="[rules.required]"
              placeholder="Table Number"
              outlined
            />
            <v-text-field
              v-if="
                orderType == 'dinein' && orderTypeSettings.require_guest_count
              "
              v-model="orderDetails.guest_count"
              :rules="[rules.required]"
              placeholder="Number of Guests"
              outlined
            />
          </v-col>
        </v-col>
        <v-col class="d-flex flex-column pb-0" />
        <v-col class="d-flex pb-0 pt-0">
          <h4 class="typeHeader typeHeader-typ2 mt-1 ">
            Pay By
          </h4>
          <v-radio-group
            v-model="paymentMethod"
            class="mt-0 ml-4 payBy"
            row
            :rules="[rules.required]"
          >
            <v-radio
              v-if="orderTypeSettings.no_payment"
              label="Pay Cash In Store"
              value="cash"
              class="cash"
            />
            <v-radio
              label="Card"
              value="card"
              class="ml-5"
            />
          </v-radio-group>
        </v-col>
        <!-- </v-col> -->
        <v-dialog
          v-model="deleteDialog"
          max-width="390"
        >
          <v-card>
            <v-card-title class="headline deleteDialogueHeader">
              Confirm Delete...!?
            </v-card-title>
            <v-card-text class="text-h6">
              Do you really want to remove this item from your cart ?
            </v-card-text>

            <v-card-actions>
              <v-spacer />

              <v-btn
                color="green"
                class="deleteDialogueButton"
                text
                @click="deleteDialog = false"
              >
                Cancel
              </v-btn>

              <v-btn
                color="red  accent-4"
                class="deleteDialogueButton"
                text
                @click="deleteItem()"
              >
                Delete...!
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div></v-form>
    <!-- </v-row> -->
    <service-not-avalibale :availability="blockStore" />
  </div>
</template>

<script>
import store from '@/store'
import { ref, watch } from '@vue/composition-api'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import moment from 'moment'
import Address from './Address.vue'
import { userService } from '../api/userService'
import { useRouter } from '../utils/utils'
import serviceNotAvalibale from './serviceNotAvalibale.vue'

export default {
  components: {
    Address,
    serviceNotAvalibale,
  },
  props: {
    isAdrress: {
      type: Boolean,
      required: false,
      default: true,
    },
    orderInfoDetails: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup(props) {
    const { router } = useRouter()

    const timings = ref([])

    const intervelTime = ref('Today')

    const timeIntervels = ref([])

    const orderTimes = ref([])

    const orderData = ref({
      name: '',
      email: '',
      mobile: '',
      city: '',
      address: '',
    })

    const orderAddressInfo = ref({ customerName: '', ordersMobile: '12345' })

    const deleteDialog = ref(false)

    const activeIndex = ref(null)

    const deleteButton = index => {
      activeIndex.value = index
      deleteDialog.value = true
    }

    const deleteItem = () => {
      deleteDialog.value = false
      store.state.user.activeUser.address.splice(activeIndex.value, 1)
      const newData = store.state.user.activeUser.address
      const data = { address: newData }
      userService.updateAddress(data).then(res => {
        store.state.user.activeUser.address = res.data
      })
    }

    const customerName = ref(null)

    const ordersEmail = ref(null)

    const ordersMobile = ref(null)

    const ordersCity = ref(null)

    const ordersAddress = ref(null)

    const todayTime = ref(null)

    const orderType = ref(null)

    const paymentMethod = ref('cash')

    const valid = ref(true)

    const orderDetails = ref({
      table_no: router.currentRoute.params.table_no || '',
    })

    const pickupTime = ref(null)

    const addressDialog = ref(false)

    const blockStore = ref(false)

    const isEmpty = inputObject => Object.keys(inputObject).length === 0

    const setUserInfo = selectedOredrType => {
      if (
        selectedOredrType !== 'delivery'
        && !isEmpty(store.state.user.activeUser)
      ) {
        // orderAddressInfo.value.customerName = `${store.state.user.activeUser.first_name} ${store.state.user.activeUser.last_name}`
        orderAddressInfo.value.customerName = `${store.state.user.activeUser.name}`
        orderAddressInfo.value.ordersMobile = `0${store.state.user.activeUser.phone
          .substring(store.state.user.activeUser.phone.indexOf(' '))
          .replace(/ /g, '')}`
      } else {
        orderAddressInfo.value.customerName = ''
        orderAddressInfo.value.ordersMobile = ''
      }
    }

    if (store.state.user.activeUser) {
      setUserInfo(orderType.value)
    }
    if (props.orderInfoDetails) {
      orderType.value = props.orderInfoDetails.orderType
      setUserInfo(orderType.value)

      paymentMethod.value = props.orderInfoDetails.paymentMethod
        ? props.orderInfoDetails.paymentMethod
        : paymentMethod.value
      timeIntervels.value = props.orderInfoDetails.storeHours
      pickupTime.value = props.orderInfoDetails.pickupTime
      // intervelTime.value = props.orderInfoDetails.intervelTime
    } else {
      orderType.value = 'dinein'
    }

    const today = () => {
      const date = new Date()
      const day = date.toLocaleString('en-us', { weekday: 'long' })
      return day.toLowerCase()
    }
    // function round(date, duration, method) {
    //   return moment(Math[method]((+date) / (+duration)) * (+duration))
    // }
    const getallTime = store.state.outlet.outletInfo.online_business_hours[today()]
    const storeSettings = store.state.outlet.outletInfo.online_store_settings

    const orderTypeSettings = ref(storeSettings.store[orderType.value] || {})

    orderTypeSettings.value.no_payment = orderTypeSettings.value.no_payment || false

    if (orderTypeSettings.value.no_payment) {
      paymentMethod.value = 'cash'
    } else {
      paymentMethod.value = 'card'
    }

    const isClosed = typeOfOrder => {
      const currentTime = moment()
      // console.log(currentHour)
      const openHours = getallTime[typeOfOrder]
      // console.log(openHours)
      // console.log(typeOfOrder)
      let timeBetween = false

      openHours.forEach(i => {
        const open = moment(i.open, 'HH:mm')
        const close = moment(i.close, 'HH:mm')
        if (i.open === '24hrs') {
          timeBetween = true
          return true
        }

        if (currentTime.isBetween(open, close)) {
          // console.log('The current time is between the specified hours.')
          timeBetween = true
          return true
        }
        // console.log('The current time is outside the specified hours.')
        return false
      })
      const isAllClosed = openHours.some(val => val.isOpen === false)

      const status = isAllClosed || !timeBetween
      return status
    }

    if (isClosed('dinein') && isClosed('collection')) {
      blockStore.value = true
    }

    const currentTime = () => {
      // const min = moment().minute()
      const min = moment().minute()

      const prepTime = orderTypeSettings.preparation_time || 15

      if (orderType.value !== 'collection') {
        todayTime.value = moment()
          .add(min > prepTime && 1, 'hours')
          .minutes(min <= prepTime ? prepTime : 0)
          .format('HHmm')
        // todayTime.value = moment().add(min > 15 && 1, 'hours').minutes(min <= 15 ? 15 : 0).format('HHmm')
      } else {
        // const next15Minutes = moment().add(20, 'minutes').add(15, 'minutes')
        // next15Minutes.minutes(Math.floor(next15Minutes.minutes() / 15) * 15)

        const next15Minutes = moment().add(prepTime, 'minutes')
        next15Minutes.minutes(Math.round(next15Minutes.minutes() / 5) * 5)
        todayTime.value = next15Minutes.format('HH:mm')
        // todayTime.value = moment().add(20, 'minutes').add(min > 15 && 1, 'hours').minutes(min <= 15 ? 15 : 0)
        //   .format('HHmm')
      }
    }

    const items = [
      { id: 1, label: 'one' },
      { id: 2, label: 'two' },
      { id: 3, label: 'three' },
    ]

    const setTimeIntervals = typeOfOrder => {
      orderTimes.value = getallTime[typeOfOrder]
      timings.value = []
      timeIntervels.value = []
      currentTime()
      orderTimes.value.forEach(i => {
        const x = {
          slotInterval: storeSettings.store[typeOfOrder].time_interval || 15,
          openTime: i.open <= todayTime.value ? todayTime.value : i.open,
          closeTime: i.close,
        }

        if (x.openTime === '24hrs') {
          x.openTime = todayTime.value
          x.closeTime = '2400'
        }

        const startTime = moment(x.openTime, 'HH:mm:')
        let endTime = moment(x.closeTime, 'HH:mm')

        if (storeSettings.store[typeOfOrder].accept_orders_upto_closing) {
          endTime = moment(x.closeTime, 'HH:mm').add(x.slotInterval, 'minutes')
        } else {
          endTime = moment(x.closeTime, 'HH:mm')
        }
        while (startTime < endTime) {
          timeIntervels.value.push(startTime.format('HH:mm'))
          startTime.add(x.slotInterval, 'minutes')
        }
      })

      const val = timeIntervels.value[0]
      if (val) {
        pickupTime.value = val
      } else {
        // blockStore.value = true
      }
    }

    const orderTypeChange = typeOfOrder => {
      if (typeOfOrder) {
        orderType.value = typeOfOrder
        const openHours = getallTime[typeOfOrder]
        const isAllClosed = openHours.some(val => val.isOpen === false)
        if (isAllClosed) {
          // blockStore.value = true
          // storeSettings.store[typeOfOrder][`allow_${typeOfOrder}`] = false
          timeIntervels.value = []
        } else {
          orderTypeSettings.value = storeSettings.store[typeOfOrder]
          if (orderType.value !== 'delivery') {
            setUserInfo(orderType.value)
          }
          setTimeIntervals(typeOfOrder)
        }
      }
    }

    // const address = ref({
    //   name: '',
    //   email: '',
    //   mobile: '',
    //   city: '',
    // })

    const addressProps = ref({})

    const editAddress = index => {
      addressDialog.value = true
      addressProps.value = store.state.user.activeUser.address[index]
    }

    const addAddress = () => {
      addressDialog.value = true
      addressProps.value = {}
    }

    const closeAddressDialog = () => {
      addressDialog.value = false
    }

    const selectAddressFetch = index => {
      // eslint-disable-next-line no-undef
      orderAddressInfo.value.customerName = store.state.user.activeUser.address[index].name
      orderAddressInfo.value.ordersEmail = store.state.user.activeUser.address[index].email
      // orderAddressInfo.value.ordersMobile = store.state.user.activeUser.address[index].mobile
      orderAddressInfo.value.ordersCity = store.state.user.activeUser.address[index].city
      orderAddressInfo.value.ordersAddress = store.state.user.activeUser.address[index].address
    }

    const addressDetails = store.state.user.activeUser.address

    const defaultAddressID = ref(null)

    if (addressDetails) {
      for (let i = 0; i < addressDetails.length; i += 1) {
        if (addressDetails[i].defaultAddress === 'true') {
          selectAddressFetch(i)
          defaultAddressID.value = addressDetails[i].addressId
        }
      }
    }
    watch(
      () => store.state.outlet.outletInfo,
      () => {
        setUserInfo(orderType.value)
        if (timeIntervels.value.length === 0) {
          setTimeIntervals(orderType.value)
        }
      },
    )
    watch(
      () => store.state.user.activeUser,
      () => {
        setUserInfo(orderType.value)
      },
    )

    watch(
      () => store.state.user.activeUser.address,
      newVal => {
        if (newVal) {
          for (let i = 0; i < newVal.length; i += 1) {
            // eslint-disable-next-line eqeqeq
            if (newVal[i].defaultAddress == 'true') {
              selectAddressFetch(i)
              defaultAddressID.value = newVal[i].addressId
            }
          }
        }
      },
    )

    return {
      valid,
      items,
      intervelTime,
      days: ['Today'],
      selected: [''],
      today,
      currentTime,
      todayTime,
      timings,
      getallTime,
      orderTimes,
      orderType,
      orderTypeChange,
      timeIntervels,
      customerName,
      ordersEmail,
      ordersMobile,
      ordersCity,
      ordersAddress,
      paymentMethod,
      pickupTime,
      orderDetails,
      orderData,
      orderAddressInfo,
      addressRadioGroup: defaultAddressID,
      addressDialog,
      editAddress,
      addAddress,
      selectAddressFetch,
      deleteDialog,
      deleteItem,
      deleteButton,
      addressProps,
      defaultAddressID,
      closeAddressDialog,
      storeSettings,
      orderTypeSettings,
      blockStore,
      isClosed,

      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        name: value => {
          const pattern = /^[a-zA-Z',.\s-]{1,50}$/
          return pattern.test(value) || 'Invalid Name.'
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
        mobile: value => {
          const pattern = /^\d+$/
          return pattern.test(value) || 'Invalid Mobile Number.'
        },
        city: value => {
          const pattern = /^[a-zA-Z',.\s-]{1,30}$/
          return pattern.test(value) || 'Invalid City.'
        },
        address: value => {
          const pattern = /^([a-zA-z0-9/\\''(),-\s]{2,255})$/
          return pattern.test(value) || 'Enter your Street and Number'
        },
      },
    }
  },
  methods: {},
}
</script>
<style>
.pickTimeAndDate .v-select__selection--comma {
  text-overflow: inherit !important;
  overflow: unset !important;
}
.typeHeader-typ2 {
  height: 32px;
  display: flex;
}
.payBy .v-input--radio-group__input {
  flex-wrap: nowrap !important;
}
</style>
