<template>
  <v-card
    class="mv-2"
    sm="12"
  >
    <v-col
      class="pa-0 orderTable"
      sm="12"
    >
      <v-card-title class="orderHead">
        MY ORDER
      </v-card-title>
      <v-col class="ma=0 pa=0 tableHead">
        <div class="order-header">
          <v-row
            variant="dark"
            class="m-0"
          >
            <v-col cols="5">
              <h5>ITEMS</h5>
            </v-col>
            <v-col cols="3">
              <h5 class="text-center qty-label pl-0 ml-0">
                QTY
              </h5>
            </v-col>
            <v-col cols="3">
              <h5 class="text-right">
                PRICE
              </h5>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col class=" myOrderList">
        <div class="m-0">
          <v-row
            v-for="(item, index) in $store.state['cart'].cartItems"
            :key="item.uuid"
          >
            <v-col
              cols="5"
              class="itemSection pr-0 pb-0"
            >
              <p class="pa-0 mb-0">
                {{ item.name }}
              </p>
              <v-row
                v-if="item.menu_modifiers"
                cols="12"
                class="d-flex pl-0 pt-2 pa-0"
              >
                <v-col
                  lg="3"
                  class="pr-0 mr-0"
                >
                  <v-img
                    v-if="item.menu_modifiers && item.menu_modifiers.length > 0"
                    src="@/assets/images/icons/edit1.svg"
                    alt="edit"
                    variant="link"
                    color="primary"
                    class="editIcon"
                    @click="customizeOrderUpdate(item, index)"
                  />
                </v-col>
                <v-col
                  lg="9"
                  class="pl-0 ml-0"
                >
                  <div
                    v-for="modifierGroup in item.menu_modifiers"
                    :key="modifierGroup.id"
                    class="modifier-group"
                    :class="
                      modifierGroup.selected_modifiers.length <= 0
                        ? 'hide-modgroup'
                        : ''
                    "
                  >
                    <div
                      v-if="modifierGroup.selected_modifiers.length > 0"
                      class="mod-group-item"
                    >
                      <small>{{ modifierGroup.name }}:</small>
                    </div>
                    <div
                      v-if="modifierGroup.selected_modifiers.length > 1"
                      class="modifierDiv"
                    >
                      <small
                        v-for="modifier in modifierGroup.selected_modifiers"
                        :key="modifier.id"
                        class="modifierName"
                      >
                        {{ modifier.name }},</small>
                    </div>
                    <div
                      v-else
                      class="modifierDiv"
                    >
                      <small
                        v-for="modifier in modifierGroup.selected_modifiers"
                        :key="modifier.id"
                        class="modifierName"
                      >
                        {{ modifier.name }}</small>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="3"
              class="itemSection orderQty"
            >
              <div class="order-detail">
                <plus-minus-field
                  :value="item.qty"
                  @change="spin(item, $event, index, 'update')"
                />
              </div>
            </v-col>
            <v-col
              cols="3"
              class="text-right itemSection priceitem"
            >
              <p>{{ (item.price * item.qty).toFixed(2) }}</p>
            </v-col>
            <v-col class="itemSection dltsection">
              <feather-icon
                icon="Trash2Icon"
                size="24"
                class="deleteButton"
                @click="
                  () => {
                    deleteButton(index)
                  }
                "
              />
            </v-col>
          </v-row>

          <div class="line_segment" />
          <v-row class="subtotal">
            <v-col
              cols="6"
              class="justify-center"
            >
              <p>Subtotal</p>
            </v-col>

            <v-col
              cols="5"
              class="subtotal-price"
            >
              <p class="text-right">
                {{ subTotal.toFixed(2) }}
              </p>
            </v-col>
          </v-row>
          <v-row
            v-if="discountAmount > 0"
            class="packing_charges"
          >
            <v-col
              cols="6"
              class="float-right"
            >
              <p>Discount</p>
            </v-col>
            <v-col
              cols="5"
              class="packing-price"
            >
              <p class="text-right">
                {{ discountAmount.toFixed(2) }}
              </p>
            </v-col>
          </v-row>
          <v-row class="order-price ">
            <v-col
              cols="6"
              class="totallbl"
            >
              <p>Grand Total</p>
            </v-col>
            <v-col
              cols="5"
              class="totalamount-col"
            >
              <p class="text-right">
                &euro;{{ totalAmount.toFixed(2) }}
              </p>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <div class="checkOut">
        <v-btn
          v-if="checkoutButton"
          class="footer-btn2 theme-btn font-weight-bold"
          @click="navigateToCart"
        >
          CHECKOUT
        </v-btn>
      </div>
    </v-col>
    <!--  Delete Dialogue box -->

    <v-dialog
      v-model="deleteDialog"
      max-width="390"
    >

      <v-card class="overflow-hidden d-flex flex-column align-items-center py-4 position-relative">
        <v-icon
          class="close-btn"
          @click="deleteDialog = false"
        >
          mdi-close-circle-outline
        </v-icon>
        <v-icon class="d-flex justify-content-center text-xl-h2 text-secondary">
          mdi-delete-forever
        </v-icon>
        <v-card-text class="text-h6 text-center">

          Do you really want to remove this item from your cart ?
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="white  accent-4"
            class="deleteDialogueButton bg-secondary text-capitalize px-3 font-weight-medium"
            text
            @click="deleteItem()"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--  Customise Dialogue box -->

    <v-dialog
      id="customize"
      ref="custom"
      v-model="customizeDialog"
      width="500"
    >
      <v-container
        v-if="activeItem"
        class="grey lighten-5 pt-0 pb-0 customise-popup"
      >
        <h2 class="text-center pop-hd">
          CUSTOMIZE
        </h2>
        <div class="customise-header sticky">
          <h4>{{ activeItem.name }}</h4>
          <h5>{{ menuItemPrice }}</h5>
        </div>
        <v-row class="mt-5 customise-heading">
          <v-col
            v-for="(option, index) in activeItem.menu_modifiers"
            :key="option.id"
            cols="12"
            md="12"
            class="mt-2"
          >
            <h2>
              {{ option.name }} (select min {{ option.min }} and max
              {{ option.max }})
            </h2>
            <h4
              v-if="
                option.min != 0 &&
                  option.min - option.selected_modifiers.length != 0 &&
                  option.selected_modifiers.length !== option.max
              "
              class="red--text pt-1"
            >
              Choose min {{ option.min - option.selected_modifiers.length }}
            </h4>

            <div
              v-for="modifier in option.modifiers"
              :key="modifier.name"
              class="checkbox-div"
            >
              <v-checkbox
                v-model="option.selected_modifiers"
                :value="modifier"
                :disabled="
                  option.selected_modifiers.length >= option.max &&
                    getItemIndex(index, modifier.id) == -1
                "
                :label="
                  modifier.price
                    ? `${modifier.name}(€ ${modifier.price})`
                    : modifier.name
                "
              />
            </div>
          </v-col>
        </v-row>
        <div class="modal-footer footer-sticky">
          <plus-minus-field
            :value="activeItem.qty"
            :type="undefined"
            @change="activeItem.qty = $event"
          />
          <v-btn
            v-if="!isUpdate"
            :disabled="selectedModifiersQty"
            @click="addToCart(activeItem)"
          >
            ADD TO ORDER -
            {{
              (
                activeItem.qty *
                (parseFloat(activeItem.price) + activeItemModifierPrice())
              ).toFixed(2)
            }}
          </v-btn>

          <v-btn
            v-if="isUpdate"
            :disabled="selectedModifiersQty"
            @click="updateCart(activeItem, 'customize')"
          >
            UPDATE ORDER -
            {{
              (
                activeItem.qty *
                (parseFloat(activeItem.price) + activeItemModifierPrice())
              ).toFixed(2)
            }}
          </v-btn>
        </div>
      </v-container>
    </v-dialog>
    <!-- <v-snackbar
      v-model="minOrdersnackbar"
      :timeout="0"
    >
      Min Order Value is {{ parseFloat(minOrderValue).toFixed(2) }}

      <v-btn
        color="pink"
        variant="text"
        class="snackbar-close"
        @click="minOrdersnackbar = false"
      >
        <v-icon
          dark
          class="icon-close"
        >
          mdi-close
        </v-icon>
      </v-btn>
    </v-snackbar> -->
  </v-card>
</template>
<script>
import store from '@/store'
import { ref, computed } from '@vue/composition-api'
// optional style for arrows & dots
// import { useRouter } from '../utils/utils'
import plusMinusField from './plusMinusField.vue'
// import SignIn from './SignIn.vue'
import useSignIn from './useSignIn'

export default {
  components: {
    plusMinusField,
    // SignIn,
  },

  props: {
    checkoutButton: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props, { emit }) {
    const categories = ref([])

    const productList = ref([])

    const activeCategory = ref(null)

    const cart = ref([])

    const activeItem = ref(null)

    const isUpdate = ref(false)

    const activeCartItemIndex = ref(null)

    const ordersDialog = ref(false)

    const customizeDialog = ref(false)

    const sumOfQty = ref(null)

    // const { router } = useRouter()

    const resultQuery = ref(null)

    const isStoreDetails = ref(false)

    const deleteDialog = ref(false)

    const activeIndex = ref(null)

    const isToCart = ref(false)

    const activeItemMinModCount = ref(0)

    const activeItemPrice = ref(0)

    const menuItemPrice = ref(0)

    const loading = ref(true)

    const sliderLimit = ref(10)

    const discountAmount = ref(0)

    const minOrdersnackbar = ref(false)

    const storeSettings = store.state.outlet.outletInfo.online_store_settings.store

    const minOrderValue = ref(storeSettings.min_order_value || 1)

    const getIndex = item => item.findIndex(i => i.id === 2)

    const secondRequest = store.dispatch('cart/fetchCategories').then(res => {
      categories.value = res.data
      activeCategory.value = categories.value[0].id
      if (categories.value.length <= 10) {
        sliderLimit.value = categories.value.length
      }
    })

    const firstRequest = store.dispatch('cart/fetchItems').then(res => {
      productList.value = res.data
      store.state.cart.cartItems.forEach(el => {
        const record = productList.value.find(item => item.id === el.id)
        if (record) {
          record.qty = el.qty
          if (el.menu_modifiers) {
            el.menu_modifiers.forEach(menuModifier => {
              const productListMenuModifiers = record.menu_modifiers.find(
                mod => mod.modifier_group_id === menuModifier.modifier_group_id,
              )
              // eslint-disable-next-line no-param-reassign
              menuModifier.modifiers = productListMenuModifiers.modifiers
            })
          }
        }
      })
    })

    Promise.all([firstRequest, secondRequest]).then(() => {
      loading.value = false
    })

    const getItemIndex = (groupIndex, modifierId) => {
      const index = activeItem.value.menu_modifiers[
        groupIndex
      ].selected_modifiers.findIndex(el => el.id === modifierId)
      return index
    }
    const lowestModPrice = data => {
      const filteredModifiers = data[0].modifiers.filter(el => el.price != null)
      const min = filteredModifiers.reduce(
        (prev, curr) => (prev.price < curr.price ? prev : curr),
        0,
      )
      const max = filteredModifiers.reduce(
        (prev, curr) => (prev.price > curr.price ? prev : curr),
        0,
      )
      const prices = { min, max }

      return prices
    }
    const setActiveItemPrice = () => {
      if (activeItem.value.price === 0 && activeItem.value.menu_modifiers) {
        activeItem.value.menu_modifiers[0].selected_modifiers.push(
          lowestModPrice(activeItem.value.menu_modifiers).min,
        )
        menuItemPrice.value = lowestModPrice(
          activeItem.value.menu_modifiers,
        ).min.price
      } else {
        menuItemPrice.value = activeItem.value.price
      }
    }

    const addToCart = (item, isCustomize = null) => {
      const cartItem = item
      isUpdate.value = false
      if (item.menu_modifiers && !customizeDialog.value) {
        customizeDialog.value = true
        activeItem.value = JSON.parse(JSON.stringify(cartItem))
        activeItem.value.qty = 1
        setActiveItemPrice()
        // eslint-disable-next-line no-param-reassign
        isCustomize = 'customize'
      } else if (cartItem.qty === 0) {
        // eslint-disable-next-line no-param-reassign
        cartItem.qty = 1
      }
      if (isCustomize === null) {
        // cartItem.price = activeItemPrice.value
        store.dispatch('cart/addToCart', cartItem).then(() => {
          const record = productList.value.find(el => el.id === cartItem.id)
          record.qty = item.qty
          customizeDialog.value = false
        })
      }
    }

    const activeItemModifierPrice = (item = null) => {
      let price = 0
      const currentItem = item != null ? item : activeItem.value
      if (currentItem.menu_modifiers) {
        currentItem.menu_modifiers.forEach(el => {
          el.selected_modifiers.forEach(e => {
            if (e.price) {
              price += e.price
            }
          })
        })
      }
      // activeItemPrice.value = activeItem.value.price + price
      return price
    }

    const updateCart = item => {
      const cartItem = JSON.parse(JSON.stringify(item))
      cartItem.price = activeItemPrice.value
      store.state.cart.cartItems[activeCartItemIndex.value].qty = cartItem.qty
      store.state.cart.cartItems[activeCartItemIndex.value].price = parseFloat(item.price) + parseFloat(activeItemModifierPrice(cartItem))
      store.state.cart.cartItems[activeCartItemIndex.value].menu_modifiers = cartItem.menu_modifiers

      localStorage.setItem('cart', JSON.stringify(store.state.cart.cartItems))
      const record = productList.value.find(el => el.id === cartItem.id)
      const menu = productList.value.find(el => el.id === item.id)
      record.qty = item.qty
      const counter = store.state.cart.cartItems.filter(i => i.id === item.id)
      if (counter.length >= 1) {
        const getSumByKey = (arr, key) => arr.reduce(
          (accumulator, current) => accumulator + Number(current[key]),
          0,
        )
        sumOfQty.value = getSumByKey(counter, 'qty')
      }
      menu.qty = sumOfQty.value
      isUpdate.value = false
      customizeDialog.value = false
      emit('customize-countUpdate', item.id)
    }

    const grouped = computed(() => {
      const groups = []
      if (!resultQuery.value) {
        groups.value = productList.value.filter(
          item => item.item_group === activeCategory.value,
        )
      } else {
        groups.value = productList.value.filter(item => resultQuery.value
          .toLowerCase()
          .split(' ')
          .every(v => item.name.toLowerCase().includes(v)))
      }
      return groups.value
    })

    const modifierChange = event => {
      activeItem.value.selected_groups[event.id] = event
    }

    const totalAmount = computed(() => {
      let total = 0
      store.state.cart.cartItems.forEach(i => {
        total += i.price * i.qty
      })
      const totalAfterDiscount = total - discountAmount.value
      return totalAfterDiscount
    })

    const subTotal = computed(() => {
      let total = 0
      store.state.cart.cartItems.forEach(i => {
        total += i.price * i.qty
      })
      return total
    })

    const totalQty = computed(() => {
      let total = 0
      store.state.cart.cartItems.forEach(i => {
        total += i.qty
      })
      return total
    })

    const totalModifiersQty = computed(() => {
      let qty = 0
      if (activeItem.value) {
        activeItem.value.menu_modifiers.forEach(el => {
          if (el.min) {
            qty += el.min
          }
        })
      }

      return qty
    })

    const selectedModifiersQty = computed(() => {
      // let length = 0
      // if (activeItem.value) {
      //   activeItem.value.menu_modifiers.forEach(el => {
      //     length += el.selected_modifiers.length
      //   })
      // }
      // return length
      let returnStatus = false
      if (activeItem.value) {
        // eslint-disable-next-line consistent-return
        activeItem.value.menu_modifiers.forEach(el => {
          if (el.min > 0 && el.selected_modifiers.length < el.min) {
            returnStatus = true
          }
          // length += el.selected_modifiers.length
        })
      }
      return returnStatus
    })

    const spin = (item, ev, index, type = undefined) => {
      const product = JSON.parse(JSON.stringify(item))
      if (item.menu_modifiers && type === 'update') {
        const record = store.state.cart.cartItems[index]
        const menu = productList.value.find(el => el.id === item.id)
        if (!record && ev !== 0) {
          addToCart(product)
        } else if (ev === 0) {
          const cartList = JSON.parse(
            JSON.stringify(store.state.cart.cartItems),
          )
          cartList.splice(index, 1)
          store.state.cart.cartItems = cartList
        }
        if (record && ev > 0) {
          record.qty = ev
        }
        menu.qty = ev
        const counter = store.state.cart.cartItems.filter(i => i.id === item.id)
        if (counter.length >= 1) {
          const getSumByKey = (arr, key) => arr.reduce(
            (accumulator, current) => accumulator + Number(current[key]),
            0,
          )
          sumOfQty.value = getSumByKey(counter, 'qty')
          menu.qty = sumOfQty.value
        } else {
          menu.qty = 0
        }
        emit('update-customizedItem', item.id)
      } else {
        // eslint-disable-next-line no-param-reassign
        product.qty = ev
        const record = store.state.cart.cartItems[index]
        const menu = productList.value.find(el => el.id === item.id)
        if (!record && ev !== 0) {
          addToCart(product)
        } else if (ev === 0) {
          const cartList = JSON.parse(
            JSON.stringify(store.state.cart.cartItems),
          )
          cartList.splice(index, 1)
          store.state.cart.cartItems = cartList
        }
        if (record && ev > 0) {
          record.qty = ev
        }
        menu.qty = ev
        emit('update-item', product.id)
        productList.value.find(el => el.id === item.id).qty = product.value
        // localStorage.setItem('cart', JSON.stringify(store.state.cart.cartItems))
      }
      localStorage.setItem('cart', JSON.stringify(store.state.cart.cartItems))
    }

    const customizeOrder = item => {
      activeItem.value = item
      setActiveItemPrice()

      activeItem.value.qty = 1
      customizeDialog.value = true
    }

    const customizeOrderUpdate = (item, index) => {
      customizeDialog.value = true
      activeItem.value = JSON.parse(JSON.stringify(item))
      const menuItem = productList.value.find(el => el.id === item.id)
      activeItem.value.price = menuItem.price
      menuItemPrice.value = menuItem.price
      isUpdate.value = true
      activeCartItemIndex.value = index
    }

    const { isSignIn } = useSignIn()

    const navigateToCart = () => {
      if (store.state.user.isLoggedIn) {
        // router.push({ name: 'cart' })
        emit('checkout')
      } else {
        emit('show-login')
      }
    }

    const deleteButton = index => {
      activeIndex.value = index
      deleteDialog.value = true
    }

    const deleteItem = () => {
      deleteDialog.value = false
      const cartItem = store.state.cart.cartItems[activeIndex.value]
      const cartItemQty = productList.value.find(el => el.id === cartItem.id)
      store.state.cart.cartItems.splice(activeIndex.value, 1)
      if (cartItem.menu_modifiers) {
        const counter = store.state.cart.cartItems.filter(
          i => i.id === cartItem.id,
        )
        if (counter.length >= 1) {
          const getSumByKey = (arr, key) => arr.reduce(
            (accumulator, current) => accumulator + Number(current[key]),
            0,
          )
          sumOfQty.value = getSumByKey(counter, 'qty')
          cartItemQty.qty = sumOfQty.value
        } else {
          cartItemQty.qty = 0
        }
      } else {
        cartItemQty.qty = 0
      }
      emit('delete-item', cartItem.id)
      localStorage.setItem('cart', JSON.stringify(store.state.cart.cartItems))
    }

    const applyCoupon = couponAmount => {
      totalAmount.value -= couponAmount
    }

    return {
      customizeOrder,
      customizeOrderUpdate,
      activeItemModifierPrice,
      totalModifiersQty,
      menuItemPrice,
      activeItemMinModCount,
      selectedModifiersQty,
      activeCategory,
      getItemIndex,
      ordersDialog,
      modifierChange,
      navigateToCart,
      spin,
      customizeDialog,
      totalAmount,
      totalQty,
      categories,
      activeItem,
      addToCart,
      updateCart,
      isUpdate,
      grouped,
      getIndex,
      cart,
      // items,
      // selectedItem,
      isSignIn,
      resultQuery,
      isStoreDetails,
      isToCart,
      lowestModPrice,
      loading,
      sliderLimit,
      // sliderResponsive,
      deleteDialog,
      deleteItem,
      deleteButton,
      show: false,
      applyCoupon,
      discountAmount,
      subTotal,
      minOrdersnackbar,
      minOrderValue,
    }
  },

  methods: {
    closeDialog(e) {
      if (e.target.className === 'v-overlay__scrim') {
        this.ordersDialog = false
      }
    },
  },
}
</script>
<style>
.snackbar-close {
  height: 30px !important;
  min-width: 30px !important;
  padding: 0 3px !important;
  border-radius: 50%;
  position: absolute;
  top: -13px;
  right: -10px;
}
.v-snack__content {
  text-align: center !important;
  font-weight: 900;
}
.v-application .text-h6{
  font-family:inherit !important;
}
.close-btn{
  position: absolute !important;
  top:8px;
  right:5px;
}
</style>
