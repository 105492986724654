<template>
  <div class="minusplusnumber">
    <div
      class="mpbtn minus"
      @click="mpminus()"
    >
      -
    </div>
    <div id="field_container">
      <input
        v-model="newValue"
        type="number"
        disabled
      >
    </div>
    <div
      class="mpbtn plus"
      @click="mpplus()"
    >
      +
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: 1,
      type: Number,
    },
    min: {
      default: 0,
      type: Number,
    },
    max: {
      default: undefined,
      type: Number,
    },
    type: {
      default: undefined,
      type: String,
    },
  },
  data() {
    return {
      newValue: 0,
    }
  },
  watch: {
    value: {
      handler(newVal) {
        if (newVal >= 0) {
          this.newValue = newVal
        }
      },
    },
  },
  created() {
    this.newValue = this.value
  },
  methods: {
    getNotificationClass(notification) {
      return `alert alert-${notification.type}`
    },
    mpplus() {
      if (this.max === undefined || (this.newValue < this.max)) {
        if (this.type === undefined) {
          this.newValue += 1
        }
        this.$emit('change', this.newValue)
      }
    },
    mpminus() {
      // if ((this.newValue) > this.min) {
      //   this.newValue -= 1
      //   this.$emit('change', this.newValue)
      // }
      let minusVal = -1
      if (this.min === undefined || (this.newValue) > this.min) {
        if (this.type === undefined) {
          this.newValue -= 1
          minusVal = this.newValue
        }
        this.$emit('change', minusVal)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
  .minusplusnumber {
      border:1px solid silver;
      border-radius:5px;
      background-color: #FFF;
      margin:0 5px 0 5px;
      display:inline-block;
      user-select: none;
  }
  .minusplusnumber div {
      display:inline-block;
  }
  .minusplusnumber #field_container input {
      text-align:center;
      font-size:16px;
      padding:0;
      border:none;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
  }
  .minusplusnumber .mpbtn {
      // padding:3px 10px 3px 10px;
      cursor:pointer;
      border-radius:5px;
  }
  .minusplusnumber .mpbtn:hover {
      background-color:#DDD;
  }
  .minusplusnumber .mpbtn:active {
      background-color:#c5c5c5;
  }

  @media (max-width:430px) {
    .myOrderList .minusplusnumber #field_container input {
      text-align: left !important;
      margin-left: 7px;
    }
}
</style>
