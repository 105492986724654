import {
  ref,
} from '@vue/composition-api'

export default function useSignIn() {
  const isSignIn = ref(false)

  return {
    isSignIn,
  }
}
