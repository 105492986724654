<template>

  <v-card class="mv-2 mb-6">

    <v-form
      ref="saveAddressForm"
      v-model="valid"
      lazy-validation
      class="w-100"
    >

      <div
        row
        class="ml-auto mr-auto"
      >

        <div class="line_segment2" />
      </div>
      <div
        v-if="isAdrress"
        row
        class="ml-auto mr-auto mb-0"
      >
        <v-col class="d-flex pb-0">
          <h4 class="typeHeader typeHeader-typ2">
            Address
          </h4>
        </v-col>
        <v-col class="mb-0 pt-0 pb-0">

          <v-col class="mb-0 pa-0 contactDetails txt-input">
            <div class="d-flex">
              <v-text-field
                v-model="address.name"
                :rules="[rules.required, rules.name]"
                placeholder="Name*"
                outlined
                required
                class="mr-3"
              />

            </div>

            <div class="d-flex">
              <v-text-field
                v-model="address.email"
                :rules="[rules.required, rules.email]"
                placeholder="E-mail *"
                outlined
                required
                class="mr-3"
              />

            </div>
            <div class="d-flex">
              <v-text-field
                v-model="address.mobile"
                :rules="[rules.required, rules.numberOnly]"
                placeholder="Mobile *"
                outlined
              />
            </div>
            <div class="d-flex">
              <v-text-field
                v-model="address.address"
                placeholder="Address"
                :rules="[rules.required]"
                outlined
                class="mr-3"
              />
            </div>
            <div class="d-flex">
              <v-text-field
                v-model="address.city"
                placeholder="City"
                :rules="[rules.required, rules.textOnly]"
                outlined
                class="mr-3"
              />
            </div>
            <div class="d-flex">
              <v-checkbox
                v-model="address.defaultAddress"
                label="Click here for Make as Default Delivery Address"
                color="primary"
                value="true"
                hide-details
              />
            </div>
          </v-col>
        </v-col>
        <div class="checkOut">
          <v-btn
            class="footer-btn2 mr-4"
            @click="saveUserAddress"
          >
            Save Address
          </v-btn>
        </div>
        <div class="line_segment2" />
      </div>
      <!-- </v-col> -->
    </v-form>
  </v-card>

</template>

<script>

import store from '@/store'
import { ref, watch } from '@vue/composition-api'
import { required } from '@validations'
import { userService } from '../api/userService'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  props: {
    isAdrress: {
      type: Boolean,
      required: false,
      default: true,
    },
    // addressProps: {},
    addressProps: {
      type: Object,
      required: false,
      default: () => ({}),
    },

  },
  setup(props, context) {
    const address = ref({
      name: '',
      email: '',
      mobile: '',
      address: '',
      city: '',
      type: '',
      addressId: '',
    })

    const valid = ref(true)

    const saveAddressForm = ref(null)

    const saveUserAddress = () => {
      const resp = saveAddressForm.value.validate()
      if (resp) {
        const newData = store.state.user.activeUser.address
          ? JSON.parse(JSON.stringify(store.state.user.activeUser.address))
          : []

        const addressID = address.value.addressId
        if (addressID !== '' && addressID !== undefined) {
          const data = newData.find(el => el.addressId === addressID)
          const objIndex = newData.findIndex((obj => obj.addressId === addressID))
          if (data.defaultAddress === 'true') {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < newData.length; i++) {
              if (i !== objIndex) {
                newData[i].defaultAddress = null
              }
            }
          }
          newData[objIndex] = data
        } else {
          newData.push(address.value)
          const UUID = () => {
          // eslint-disable-next-line no-bitwise
          // eslint-disable-next-line no-mixed-operators, no-bitwise
            const UID = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16))
            return UID
          }
          address.value.addressId = UUID()
        }
        const data = { address: newData }
        userService.updateAddress(data).then(res => {
          store.state.user.activeUser.address = res.data
          saveAddressForm.value.reset()
          context.emit('change', false)
        })
      }
    }

    if (props.addressProps) {
      address.value = props.addressProps
    }

    watch(() => props.addressProps, addressProps => {
      address.value = addressProps
    })

    return {
      valid,
      saveUserAddress,
      required,
      address,
      saveAddressForm,

      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        name: value => {
          const pattern = /(?=.{1,40}$)[a-zA-Z]+(?:[-' ][a-zA-Z]+)*$/
          return pattern.test(value) || 'Invalid Name.'
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
        numberOnly: value => {
          const pattern = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/
          return pattern.test(value) || 'Invalid Mobile Number.'
        },
        textOnly: value => {
          const pattern = /^[a-zA-Z',.\s-]{1,30}$/
          return pattern.test(value) || 'Invalid Data.'
        },
      },
    }
  },
}
</script>
